<script>
import Login from '../modules/auth/pages/Login'
import OrdersPage from '../modules/orders/pages/OrdersPage'
import ReturnsPage from '../modules/returns/pages/ReturnsPage'
import BackordersPage from '../modules/backorders/pages/BackordersPage'
import BatchOverviewPage from '../modules/batch-overview/pages/BatchOverviewPage'
import InventoryItemsPage from '../modules/inventory-items/pages/InventoryItemsPage'
import BestsellerPage from '../modules/bestsellers/pages/BestsellerPage'
import GoodsPage from '../modules/incoming-goods/pages/GoodsPage'
import StockPage from '../modules/stock-movements/pages/StockPage'
import ServiceQualityPage from '../modules/service-quality/pages/ServiceQualityPage'
import ServiceCarrierPage from '@/modules/service-carrier/pages/ServiceCarrierPage'
import RolesPage from '../modules/access/pages/RolesPage'
import UsersPage from '@/modules/access/pages/UsersPage'
import ProcessFlowPage from '@/modules/processflow/pages/ProcessFlowPage'
import ReportsReturnPage from '../modules/reports-returns/pages/ReportsReturnPage'
import ReportsRevenuePage from '../modules/reports-revenue/pages/ReportsRevenuePage'
import ReportsRevenueDetailPage from '../modules/reports-revenue/pages/ReportsRevenueDetailPage'
import ReportsTimeofgoodsreceiptPage from '../modules/reports-timeofgoodsreceipt/pages/ReportsTimeofgoodsreceiptPage'
import ReportsSkuRevenuePage from '../modules/reports-skurevenue/pages/ReportsSkuRevenuePage'
import ReportsLabelErrorsPage from '../modules/reports-labelerrors/pages/ReportsLabelErrorsPage'
import ReportsErrorsPage from '../modules/reports-errors/pages/ReportsErrorsPage'
import OrderDeliveryDetailPage from '../modules/orders/pages/OrderDeliveryDetailPage'
import InvoiceFulfillmentPage from '../modules/invoice-fulfillments/pages/InvoiceFulfillmentPage'
import SupportAddressChangePage from '../modules/support/pages/SupportAddressChangePage.vue'
import SupportCancelOrderPage from '@/modules/support/pages/SupportCancelOrderPage.vue'
import OrderCreatePage from '@/modules/orders/pages/OrderCreatePage.vue'
import OrderPurchasePage from '@/modules/inbound-orders/pages/OrderPurchasePage.vue'
import ProfilePage from '@/modules/profile/pages/ProfilePage.vue'
import i18n from '@/i18n'
import ReportsPackagingPage from '../modules/reports-packaging/pages/ReportsPackagingPage.vue'
import WidgetPage from '@/modules/widget/pages/WidgetPage.vue'
import OrderPurchaseDetailPage  from "@/modules/inbound-orders/pages/OrderPurchaseDetailPage.vue"
import ResetPasswordPage from "@/modules/auth/pages/ResetPasswordPage.vue";

const routes = [
  {path: '/login', component: Login},
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: ResetPasswordPage
  },

  {path: '*', redirect: '/dashboard'},
  {path: '/dashboard', component: ProcessFlowPage, title: i18n.t('sidebar.menu.dashboard')},
  {path: '/orders/deliveries', component: OrdersPage, title: i18n.t('orders.ordersOrderOverview')},
  {
    path: '/invoices/fulfillments',
    component: InvoiceFulfillmentPage,
    title: i18n.t('invoice-fulfillments.page.invoicesFulfillments')
  },
  {path: '/orders/order-delivery-detail', name: 'order-delivery-detail', component: OrderDeliveryDetailPage},
  {path: '/orders/returns', component: ReturnsPage, title: i18n.t('returns.page.ordersReturnOverview')},
  {path: '/orders/backorders', component: BackordersPage, title: i18n.t('backorders.page.header')},
  {path: '/orders/order-create', component: OrderCreatePage, title: i18n.t('ordersCreateOrder')},
  {path: '/orders/order-inbound', component: OrderPurchasePage, title: i18n.t('ordersInboundOrder')},
  {path: '/orders/order-inbound-detail', component: OrderPurchaseDetailPage, title: i18n.t('ordersInboundOrderDetail')},
  {path: '/orders/order-cancel', component: SupportCancelOrderPage, title: i18n.t('supportCancellation')},
  {path: '/orders/address-change', component: SupportAddressChangePage, title: i18n.t('supportChangeAddress')},
  {path: '/products/batch-overview', component: BatchOverviewPage, title: i18n.t('batch-overview.page.header')},
  {
    path: '/products/inventory',
    component: InventoryItemsPage,
    title: i18n.t('inventory-items.page.productsInventoryOverview')
  },
  {path: '/products/bestseller', component: BestsellerPage, title: i18n.t('bestseller.page.header')},
  {path: '/products/goods', component: GoodsPage, title: i18n.t('incoming-goods.page.header')},
  {path: '/products/stock', component: StockPage, title: i18n.t('stock.page.productsStockMovements')},
  {
    path: '/reports/service-quality',
    component: ServiceQualityPage,
    title: i18n.t('service-quality.page.reportsServiceQuality')
  },
  {
    path: '/reports/service-carrier',
    component: ServiceCarrierPage,
    title: i18n.t('service-carrier.page.reportsServiceCarrier')
  },
  {
    path: '/reports/reports-returns',
    component: ReportsReturnPage,
    title: i18n.t('reports-return.page.reportsServiceQualityReturns')
  },
  {
    path: '/reports/reports-revenue',
    component: ReportsRevenuePage,
    title: i18n.t('reports-revenue.page.reportsRevenue')
  },
  {path: '/reports/reports-revenue-detail', name: 'reports-revenue-detail', component: ReportsRevenueDetailPage},
  {
    path: '/reports/reports-timeofgoodsreceipt',
    component: ReportsTimeofgoodsreceiptPage,
    title: i18n.t('reports-timeofgoodsreceipt.page.reportsServiceQualityReturns')
  },
  {
    path: '/reports/reports-skurevenue',
    component: ReportsSkuRevenuePage,
    title: i18n.t('reports-sku-revenue.page.skuRevenue')
  },
  {
    path: '/reports/reports-labelerrors',
    component: ReportsLabelErrorsPage,
    title: i18n.t('reports-label-errors.labelErrors')
  },
  {path: '/reports/reports-errors', component: ReportsErrorsPage, title: i18n.t('reports-error.errors')},
  {path: '/access/roles', component: RolesPage, title: i18n.t('roles.accessRoles')},
  {path: '/access/users', component: UsersPage, title: i18n.t('users.page.accessUsers')},
  {path: '/profile', component: ProfilePage, name: 'profile', title: 'Profile'},
  {path: '/reports/reports-packaging', component: ReportsPackagingPage, title: i18n.t('packagingMaterials')},
  {path: '/widget/return-widget', component: WidgetPage, title: 'Return Widget'}
]


export default routes
</script>
