<template>
  <div>
    <b-alert
      :show="warning != null"
      variant="warning mt-2"
    >
      {{ warning }}
    </b-alert>
    <b-alert
      :show="error != null"
      variant="danger mt-2"
    >
      {{ error }}
    </b-alert>
    <b-alert
      v-if="success"
      :show="success"
      variant="success mt-2"
    >
      {{ $t('orderCancelledSuccessfully') }}
    </b-alert>
    <b-card
      v-if="showDetails"
      :title="$t('order')"
      class="mt-2"
    >
      <b-form novalidate>
        <div class="row">
          <b-form-group
            id="address-ordernr"
            :label="$t('cancelOrder.orderNumber')"
            class="col"
            label-for="order-input-ordernr"
          >
            <b-form-input
              id="order-input-ordernr"
              :value="order.orderNr"
              disabled/>
          </b-form-group>
        </div>
        <div class="row mt-2">
          <b-form-group
            id="address-name1"
            :label="$t('name1')"
            class="col"
            label-for="order-input-name1">
            <b-form-input
              id="order-input-name1"
              :readonly="readonly"
              :state="getFieldState('name1')"
              :value="order.name1"
              required/>
            <b-form-invalid-feedback
              v-if="stateInvalid && groupedValidations['name1']"
              id="order-input-name1-feedback"
            >
              {{ groupedValidations['name1'].message }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="address-name2"
            :label="$t('name2')"
            class="col"
            label-for="order-input-name2">
            <b-form-input
              id="order-input-name2"
              :readonly="readonly"
              :value="order.name2"
            />
          </b-form-group>
        </div>
        <div class="row mt-2">
          <b-form-group
            id="address-street"
            :label="$t('street')"
            class="col"
            label-for="order-input-street">
            <b-form-input
              id="order-input-street"
              :readonly="readonly"
              :state="getFieldState('name1')"
              :value="order.street"
              required
            />
            <b-form-invalid-feedback
              v-if="stateInvalid && groupedValidations['street']"
              id="order-input-name1-feedback"
            >
              {{ groupedValidations['street'].message }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="address-street2"
            :label="$t('street2')"
            class="col"
            label-for="order-input-street2">
            <b-form-input
              id="order-input-street2"
              :readonly="readonly"
              :value="order.street2"
            />
          </b-form-group>
          <b-form-group
            id="address-streetnr"
            :label="$t('streetNumber')"
            class="col-2"
            label-for="order-input-streetnr">
            <b-form-input
              id="order-input-streetnr"
              :readonly="readonly"
              :value=" order.streetNr"
            />
          </b-form-group>
        </div>
        <div class="row mt-2">
          <b-form-group
            id="address-country"
            :label="$t('country')"
            class="col"
            label-for="order-input-country">
            <b-form-input
              id="order-input-country"
              :readonly="readonly"
              :state="getFieldState('country')"
              :value="order.country"
              required
            />
            <b-form-invalid-feedback
              v-if="stateInvalid && groupedValidations['country']"
              id="order-input-country-feedback"
            >
              {{ groupedValidations['country'].message }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="address-city"
            :label="$t('city')"
            class="col"
            label-for="order-input-city">
            <b-form-input
              id="order-input-city"
              :readonly="readonly"
              :state="getFieldState('city')"
              :value="order.city"
              required
            />
            <b-form-invalid-feedback
              v-if="stateInvalid && groupedValidations['city']"
              id="order-input-city-feedback"
            >
              {{ groupedValidations['city'].message }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="address-zip"
            :label="$t('zipCode')"
            class="col-2"
            label-for="order-input-zip">
            <b-form-input
              id="order-input-zip"
              :readonly="readonly"
              :state="getFieldState('zip')"
              :value="order.zip"
              required
            />
            <b-form-invalid-feedback
              v-if="stateInvalid && groupedValidations['zip']"
              id="order-input-zip-feedback"
            >
              {{ groupedValidations['zip'].message }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="row mt-2">
          <b-form-group
            id="address-email"
            :label="$t('email')"
            class="col"
            label-for="order-input-email">
            <b-form-input
              id="order-input-email"
              :readonly="readonly"
              :value="order.email"
              type="email"
            />
          </b-form-group>
          <b-form-group
            id="address-phone"
            :label="$t('phone')"
            class="col"
            label-for="order-input-phone">
            <b-form-input
              id="order-input-phone"
              :readonly="readonly"
              :value="order.phone"
            />
          </b-form-group>
        </div>
      </b-form>
    </b-card>

    <b-card :title="$t('cancel')" class="mt-2">
      <b-form @submit.prevent="onSubmit">
        <b-form-group
          id="cancel-form-notes"
          :label="$t('notes')"
          label-for="cancel-form-input-notes">
          <b-form-textarea
            id="npm"
            v-model="cancelForm.notes"
            required
            :disabled="orderShipped"/>
        </b-form-group>
        <b-button
          :disabled="loading || orderShipped"
          class="mt-3"
          type="submit"
          variant="danger"
        >
          <b-spinner v-if="loading" small/>
          {{ loading ? 'Loading...' : 'Cancel order' }}
        </b-button>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import { client } from '@/client/client'

export default {
  name: 'CancelOrderForm',
  props: ['order', 'cpy', 'showDetails'],
  emits: ['updateOrder'],
  mounted() {
    this.stateInvalid = false
    this.cancelForm.orderNr = this.$props.order.orderNr

    if (this.orderShipped) this.error = this.shippedOrderMessage
  },
  data() {
    return {
      error: null,
      success: false,
      warning: null,
      loading: false,
      stateInvalid: false,
      validations: null,
      readonly: true,
      shippedOrderMessage: 'This order cannot be canceled as it has already been shipped',
      cancelForm: {
        notes: '',
        orderNr: '',
        cpy: this.$props.cpy
      }
    }
  },
  computed: {
    groupedValidations() {
      const validations = {}

      this.validations.forEach(validation => {
        const name = validation.path.split('.')[1]
        const message = validation.message.split(': ')[1]
        validations[name] = {...validation, message}
      })

      return validations
    },
    orderShipped() {
      return !!this.$props.order.shippedAt && this.$props.order.shippedAt !== '1753-01-01 00:00:00.0'
    }
  },
  methods: {
    async onSubmit() {
      this.$swal.fire({
        title: 'Are you sure you want to cancel the order?',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.cancelOrder()
        }
      })
    },
    async cancelOrder() {
      this.error = null
      this.stateInvalid = false
      this.validations = null
      this.success = false

      try {
        this.loading = true
        const response = await client.change_address().order_cancel(this.cancelForm)
        this.$emit('updateOrder', this.$props.order, response.data.status)
        this.success = true
      } catch (error) {
        console.error('Error:', error)
        this.error = `${error.response.data.error.code}: ${error.response.data.error.detail}`

        if (error.response.data.error.code === 'DADCI004') {
          this.stateInvalid = true
          this.validations = error.response.data.error.validation
        }
      } finally {
        this.loading = false
      }
    },
    getFieldState(name) {
      return this.stateInvalid && this.groupedValidations[name] ? false : null
    }
  }
}
</script>
