import axios from "axios";
import { dashboardClientConfig } from "../config";

let apiRoot = dashboardClientConfig.apiRoot;

export function auth() {
    return {
        login: (username, password) => axios.post(`${apiRoot}/auth/login`, { username, password }),
        me: () => axios.get(`${apiRoot}/auth/me`),


        forgotPassword: (email,baseurl) =>
            axios.post(`${apiRoot}/auth/forgot-password`, null, {
                params: { email,baseurl}
            }),

        resetPassword: (token, newPassword) =>
            axios.post(`${apiRoot}/auth/reset-password`, null, {
                params: { token, newPassword }
            })
    }
}
