import axios from "axios";
import {dashboardClientConfig} from "../config";
import { requestManager } from '@/assets/requestManager.js';

let apiRoot = dashboardClientConfig.apiRoot;
let returnsRootUrl = apiRoot + "/inbounds";

export function inbounds() {
    return {
        all: (obj) => axios.post(returnsRootUrl, obj, {
            cancelToken: new axios.CancelToken(function executor(cancel) {
              requestManager.addRequest({ cancel }); 
            })
          }),
        edit: (obj) => axios.post(
            `${returnsRootUrl}/edit?pohnum=${encodeURIComponent(obj.pohnum)}`,
            {}, // Empty body since we are passing `pohnum` as a query parameter
            {
                cancelToken: new axios.CancelToken(function executor(cancel) {
                    requestManager.addRequest({ cancel });
                })
            }
        ),

        download_excel: (obj) => axios.post(returnsRootUrl + "/exportToExcel", obj, { responseType: 'blob'}, {
            cancelToken: new axios.CancelToken(function executor(cancel) {
              requestManager.addRequest({ cancel }); 
            })
          }),

    }
}
