<template>
  <div class="login login-v1 login-custom">
    <div class="login-container">
      <div class="login-header">
        <div class="brand">
          <img alt="" src="../../../assets/img/logo/logo.png"/>
        </div>
        <div class="icon">
          <slot name="icon"><i class="fa fa-lock"/></slot>
        </div>
      </div>
      <div class="login-body">
        <div class="login-content fs-13px">
          <form @submit.prevent="login">
            <div v-if="loginError" class="alert alert-danger">{{ loginError }}</div>
            <div class="form-floating mb-20px">
              <input v-model="username" placeholder="Username" class="form-control fs-13px h-45px" required type="text"/>
              <label>Username</label>
            </div>
            <div class="form-floating mb-20px">
              <input v-model="password" placeholder="Password" class="form-control fs-13px h-45px" required type="password"/>
              <label>Password</label>
            </div>
            <div class="login-buttons">
              <b-btn :disabled="loading" class="btn h-45px btn-success d-block w-100 btn-lg" type="submit">
                <span v-if="loading">
                  <b-spinner small></b-spinner>
                </span>
                <span v-else>Login</span>
              </b-btn>
              <b-btn class="btn h-45px btn-warning d-block w-100 btn-lg mt-2" @click.prevent="showReset = true">
                Forgot Password
              </b-btn>
            </div>
          </form>

          <div v-if="showReset" class="mt-3">
            <h5>Reset Password</h5>
            <input v-model="resetEmail" placeholder="Your Email" class="form-control fs-13px h-45px mb-2" type="email" required/>
            <b-btn :disabled="resetLoading" class="btn btn-primary w-100" @click="requestReset">
              <span v-if="resetLoading">
                <b-spinner small></b-spinner>
              </span>
              <span v-else>Submit</span>
            </b-btn>
            <div v-if="resetMessage" class="alert alert-info mt-2">{{ resetMessage }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppOptions from '../../../config/AppOptions'
import { client } from '@/client/client'

export default {
  data() {
    return {
      username: '',
      password: '',
      loginError: '',
      loading: false,
      showReset: false,
      resetEmail: '',
      resetMessage: '',
      resetLoading: false
    }
  },
  created() {
    AppOptions.appEmpty = true
    AppOptions.appWithFooter = false
  },
  beforeRouteLeave(to, from, next) {
    AppOptions.appEmpty = false
    AppOptions.appWithFooter = true
    next()
  },
  methods: {
    async login() {
      try {
        this.loading = true
        const response = await client.auth().login(this.username, this.password)
        localStorage.setItem('token', response.data.token)
        refreshToken(response.data.expiration)

        const userResponse = await client.auth().me()
        const user = userResponse.data
        this.$store.commit('currentUser', user)
        if (user && user.defaultMandant && user.defaultMandant.cpy) {
          this.$store.commit('currentCpy', user.defaultMandant.cpy)
        } else if (user && user.cpyList && this.$store.getters.cpy.length > 0 && user.cpyList.includes(this.$store.getters.cpy[0])) {
          this.$store.commit('currentCpy', user.cpyList[0])
        }

        this.loading = false
        this.$root.$emit('login')
        await this.$router.push({path: user.preferredView || '/dashboard'})
      } catch (error) {
        this.loading = false
        if (error.response && error.response.status === 403) {
          this.loginError = this.$t('loginFailed')
        }
      }
    },
    async requestReset() {
      const domain = window.location.origin;
      try {
        this.resetLoading = true
        await client.auth().forgotPassword(this.resetEmail,domain)
        this.resetMessage = 'Check your email for reset instructions.'
      } catch (error) {
        this.resetMessage = 'Reset failed.'
      } finally {
        this.resetLoading = false
      }
    }
  }
}

function refreshToken(expiration) {
  if (expiration) {
    setTimeout(doRefresh, expiration * 0.9)
  }
  function doRefresh() {
    client.auth().refresh()
        .then(response => {
          localStorage.setItem('token', response.data.token)
          refreshToken(response.data.expiration)
        })
  }
}
</script>

<style scoped>
.brand img {
  width: 240px;
}
.login-custom {
  background-color: white;
  background-image: none;
}
</style>
