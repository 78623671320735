import Vue from 'vue'
import VueX from 'vuex'
import routes from './config/AppRoutes'

// plugins
import VueRouter from 'vue-router'
import VueBootstrap from 'bootstrap-vue'
import VueNVD3 from 'vue-nvd3'
import VueInsProgressBar from 'vue-ins-progress-bar'
import VueEventCalendar from 'vue-event-calendar'
import VueSparkline from 'vue-sparklines'
// import * as VueGoogleMaps from 'vue2-google-maps'
import Vueditor from '@agametov/vueditor'
import VueHljs from 'vue-hljs'
import hljs from 'highlight.js'
import VueSweetalert2 from 'vue-sweetalert2'
import VueNotification from 'vue-notification'
import VuePanel from './plugins/panel/'
import VueDateTimePicker from 'vue-bootstrap-datetimepicker'
import VueSelect from 'vue-select'
import VueDatepicker from 'vuejs-datepicker/dist/vuejs-datepicker.esm.js'
import VueMaskedInput from 'vue-maskedinput'
import VueInputTag from 'vue-input-tag'
import VueSlider from 'vue-slider-component'
import VueGoodTable from 'vue-good-table'
import VueCountdown from '@chenfengyuan/vue-countdown'
import VueColorpicker from 'vue-pop-colorpicker'
import VueCustomScrollbar from 'vue-custom-scrollbar'
import VueApexCharts from 'vue-apexcharts'
import DateRangePicker from 'vue2-daterange-picker'
import VueConfirmDialog from 'vue-confirm-dialog'
import Multiselect from 'vue-multiselect'
import VueTreeselect from 'vue-treeselect'
import i18n from '@/i18n'
import VueCountryCode from 'vue-country-code'

// plugins css
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'nvd3/build/nv.d3.min.css'
import 'vue-event-calendar/dist/style.css'
import 'vue-hljs/dist/style.css'
import '@agametov/vueditor/dist/style/vueditor.min.css'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import 'simple-line-icons/css/simple-line-icons.css'
import 'flag-icon-css/css/flag-icons.min.css'
import 'ionicons/dist/css/ionicons.min.css'
import 'vue-good-table/dist/vue-good-table.css'
import 'vue-select/dist/vue-select.css'
import 'vue-slider-component/theme/antd.css'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

// color admin css
import './scss/vue.scss'
import './scss/_dashboard.scss'
import 'bootstrap-social/bootstrap-social.css'

import axios from 'axios'
// mixins
import security from './mixins/security'
import filters from './mixins/filters'

import Layout from './layout/Layout.vue'
//modules
import { ModuleRegistry } from './modules/api'

import AuthModule from './modules/auth/module'

import './assets/css/main.css'
import Vuelidate from 'vuelidate'

ModuleRegistry.register([
  AuthModule
])

Vue.config.productionTip = false

Vue.use(VueX)
Vue.use(VueRouter)
Vue.use(VueBootstrap)
Vue.use(VueNVD3)
Vue.use(VueEventCalendar, {locale: 'en'})
Vue.use(VueSparkline)
Vue.use(Vueditor)
Vue.use(VueHljs, {hljs})
Vue.use(VueSweetalert2)
Vue.use(VueNotification)
Vue.use(VuePanel)
Vue.use(VueDateTimePicker)
Vue.use(VueGoodTable)
Vue.use(VueColorpicker)
Vue.use(VueConfirmDialog)
Vue.use(Multiselect)
Vue.use(VueTreeselect)
Vue.use(VueCountryCode)
Vue.use(Vuelidate)
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: '',
//     libraries: 'places'
//   }
// })
Vue.use(VueInsProgressBar, {
  position: 'fixed',
  show: true,
  height: '3px'
})
Vue.component('v-select', VueSelect)
Vue.component('datepicker', VueDatepicker)
Vue.component('masked-input', VueMaskedInput)
Vue.component('input-tag', VueInputTag)
Vue.component('vue-slider', VueSlider)
Vue.component('vue-custom-scrollbar', VueCustomScrollbar)
Vue.component('apexchart', VueApexCharts)
Vue.component('date-range-picker', DateRangePicker)
Vue.component(VueCountdown.name, VueCountdown)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
Vue.component('multiselect', Multiselect)
Vue.component('treeselect', VueTreeselect.Treeselect)


Vue.mixin(filters)
Vue.mixin(security)

const router = new VueRouter({
  routes
})

const publicStates = ['/login', '/reset-password'];


 function isTokenValid() {
   const token = localStorage.getItem('token')
   return !!token
 }


axios.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      const currentPath = router.currentRoute.path;

      if (publicStates.includes(currentPath)) {
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      }

      // If route is not public but there's no token, redirect to /login
      if (!isTokenValid()) {
        router.push({ path: '/login' });
        return Promise.reject(new Error('No token found'));
      }

      config.headers.Authorization = `Bearer ${token}`;

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
);

/*axios.interceptors.request.use(function(request) {
  let token = localStorage.getItem("token");
  console.log(token +"Reuqst: " + request.url);
  if (token === null) {
    if (router.path !== '/login') {
      router.push({ path: '/login' });
    }
  }
  else if (isTokenValid()) {
    request.headers['Authorization'] = 'Bearer ' + token;
  } else if (!publicStates.includes(router.currentRoute.fullPath)) {
    
    if (router.path !== '/login') {
      router.push({ path: '/login' });
    }
  }
  return request;
},
(error) => {
  return Promise.reject(error);
});*/

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response && error.response.status === 401) {
    localStorage.removeItem('token')
    if (!publicStates.includes(router.currentRoute.path)) {
      router.push({path: '/login'})
      return Promise.reject(new Error('Invalid token'))
    }
  } else {
    window.console.log(error)
    window.console.log(error.response)
  }
  return Promise.reject(error)
})

const store = new VueX.Store({
  state: {
    user: undefined,
    cpy: undefined,
    currentStofcy: [],
    selectedStofcy: []
  },
  mutations: {
    resetState(state) {
      state.currentStofcy = []
      state.selectedStofcy = []
      state.user = undefined
      state.cpy = undefined

    },
    currentUser: (state, user) => {
      state.user = user
    },
    currentCpy: (state, cpy) => {
      state.cpy = cpy
    },
    currentStofcy(state, stofcyArr) {
      state.currentStofcy = stofcyArr
    },
    selectedStofcy(state, stofcyArr) {
      state.selectedStofcy = stofcyArr
    }
  },
  getters: {
    user(state) {
      return state.user
    },
    userId: state => {
      return state.user ? state.user.id : ''
    },
    cpy: state => {
      return state.user ? state.user.cpyList : []
    },
    currentStofcy: state => {
      return state.user ? state.currentStofcy : []
    },
    selectedStofcy: state => {
      return state.user ? state.selectedStofcy : []
    },
    currentCpy: state => {
      return state.cpy
    },
    userFullName: state => {
      if (state.user) {
        if (state.user.firstName && state.user.lastName) {
          return state.user.firstName + ' ' + state.user.lastName
        } else {
          return state.user.login
        }
      } else {
        return ''
      }
    },
    userEmail: state => {
      if (state.user) {
        return state.user.email
      }
    },
    userPermissions: state => {
      return state.user && state.user.permissions ? state.user.permissions : []
    },
    userLanguage: state => {
      return state.user && state.user.language ? state.user.language.toLowerCase().trim() : 'en'
    },
    userPreferredView: state => {
      return state.user && state.user.preferredView ? state.user.preferredView : '/dashboard'
    },
    userDefaultDateFilter: state => {
      return state.user && state.user.defaultDateFilter ? state.user.defaultDateFilter : 'today'
    },
    userDefaultMandant: state => {
      return state.user && state.user.defaultMandant ? state.user.defaultMandant.cpy : ''
    },
    userDefaultDateFilterName: state => {
      const filters = {
        'today': i18n.t('today'),
        'yesterday': i18n.t('yesterday'),
        'last_7': i18n.t('lastWeek'),
        'last_30': i18n.t('last30Days'),
        'last_6m': i18n.t('last6Months'),
        'last_year': i18n.t('lastYear')
      }

      return state.user && state.user.defaultDateFilter ? filters[state.user.defaultDateFilter] : i18n.t('today')
    }
  }

})

export const bus = new Vue()
new Vue({
  render: h => h(Layout),
  router,
  store,
  i18n
}).$mount('#app')
