<template>
  <div class="login login-v1 login-custom">
    <div class="login-container">
      <div class="login-header">
        <div class="brand">
          <img alt="" src="../../../assets/img/logo/logo.png" />
        </div>
        <div class="icon">
          <slot name="icon"><i class="fa fa-key" /></slot>
        </div>
      </div>

      <div class="login-body">
        <div class="login-content fs-13px">
          <!-- Password Reset Form -->
          <form @submit.prevent="resetPassword">
            <!-- Show any error messages -->
            <div v-if="resetError" class="alert alert-danger">{{ resetError }}</div>

            <!-- New Password -->
            <div class="form-floating mb-20px">
              <input
                  v-model="newPassword"
                  placeholder="New Password"
                  class="form-control fs-13px h-45px"
                  required
                  type="password"
              />
              <label>New Password</label>
            </div>

            <!-- Confirm Password -->
            <div class="form-floating mb-20px">
              <input
                  v-model="confirmPassword"
                  placeholder="Confirm Password"
                  class="form-control fs-13px h-45px"
                  required
                  type="password"
              />
              <label>Confirm Password</label>
            </div>

            <!-- Submit Button -->
            <div class="login-buttons">
              <b-btn
                  :disabled="loading"
                  class="btn h-45px btn-success d-block w-100 btn-lg"
                  type="submit"
              >
                <span v-if="loading">
                  <b-spinner small></b-spinner>
                </span>
                <span v-else>Submit</span>
              </b-btn>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { client } from '@/client/client'
import AppOptions from '../../../config/AppOptions'

export default {
  name: 'ResetPasswordPage',
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
      resetError: '',
      loading: false
    };
  },
  created() {
    AppOptions.appEmpty = true
    AppOptions.appWithFooter = false
  },
  methods: {
    async resetPassword() {
      if (this.newPassword !== this.confirmPassword) {
        this.resetError = 'Passwords do not match.';
        return;
      }


      const token = this.$route.query.token;
      if (!token) {
        this.resetError = 'Missing reset token.';
        return;
      }

      try {
        this.resetError = '';
        this.loading = true;

        await client.auth().resetPassword(token, this.newPassword);
        alert('Password successfully updated!');

        this.$router.push('/login');
      } catch (error) {
        console.error(error);
        this.resetError = 'Failed to reset password. Please try again.';
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.brand img {
  width: 240px;
}

.login-custom {
  background-color: white;
  background-image: none;
}
</style>
